var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-6" },
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "vx-row mt-2" }, [
            _c("div", { staticClass: "vx-col sm:w-1/1 w-full mb-2" }, [
              _c("b", [_vm._v("Prova Prática")]),
            ]),
            _vm.pacticalTest
              ? _c("div", { staticClass: "vx-col sm:w-1/1 w-full mb-2" }, [
                  _vm._v(
                    " " + _vm._s(_vm.pacticalTest.questionnaire_name) + " "
                  ),
                ])
              : _vm._e(),
          ]),
          !_vm.isFullscreen
            ? _c("div", { staticClass: "vx-row mt-2" }, [
                _c(
                  "div",
                  { staticClass: "vx-col sm:w-1/1 w-full mb-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        id: "student_name",
                        label: _vm.$t("practical_test.dashboard.group"),
                      },
                      model: {
                        value: _vm.group,
                        callback: function ($$v) {
                          _vm.group = $$v
                        },
                        expression: "group",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          !_vm.isFullscreen
            ? _c("div", { staticClass: "vx-row mt-2" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full flex justify-end mb-2" },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "filter-button",
                        attrs: {
                          color: "primary",
                          icon: "search",
                          title: _vm.$t("buscar"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.filter()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("filtrar")))]
                    ),
                    _c(
                      "vs-button",
                      {
                        staticClass: "ml-3",
                        attrs: { type: "border" },
                        on: { click: _vm.goBack },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
                    ),
                    _c(
                      "vs-button",
                      {
                        staticClass: "ml-3",
                        attrs: { icon: "monitor", type: "border" },
                        on: { click: _vm.modeScreenfull },
                      },
                      [_vm._v(" Modo quiosque ")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.praticalTestStudents.length > 0
            ? _c("vs-divider", { staticClass: "mt-4" }, [_vm._v("Alunos")])
            : _vm._e(),
          _c("div", { staticClass: "vx-row mt-2" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c(
                  "vs-table",
                  {
                    attrs: {
                      data: _vm.praticalTestStudents,
                      sst: true,
                      "current-sort-type": "asc",
                      "no-data-text": _vm.noDataText,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ data }) {
                          return _vm._l(data, function (tr, i) {
                            return _c(
                              "vs-tr",
                              { key: i },
                              [
                                _c(
                                  "vs-td",
                                  { staticClass: "break-all p-1 pl-4" },
                                  [_vm._v(" " + _vm._s(tr.data.name) + " ")]
                                ),
                                _c(
                                  "vs-td",
                                  { staticClass: "break-all p-1 pl-4" },
                                  [_vm._v(" " + _vm._s(tr.data.email) + " ")]
                                ),
                                _c(
                                  "vs-td",
                                  { staticClass: "break-all p-1 pl-4" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(tr.data.identifier) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  [
                                    _c(
                                      "vs-table",
                                      {
                                        attrs: { data: _vm.sections },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ data }) {
                                                return _vm._l(
                                                  data,
                                                  function (trSection, j) {
                                                    return _c(
                                                      "vs-tr",
                                                      {
                                                        key: j,
                                                        class: _vm.classSection(
                                                          trSection,
                                                          tr.data
                                                        ),
                                                        staticStyle: {
                                                          "border-bottom":
                                                            "thin solid",
                                                        },
                                                      },
                                                      [
                                                        _c("vs-td", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                trSection.data
                                                                  .title
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c("vs-td", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getSectionStartAt(
                                                                  trSection,
                                                                  tr.data
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c("vs-td", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getSectionQtyQuestion(
                                                                  trSection,
                                                                  tr.data
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c("vs-td", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getSectionQtyAnswered(
                                                                  trSection,
                                                                  tr.data
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "thead" },
                                          [
                                            _c(
                                              "vs-th",
                                              { attrs: { width: "50%" } },
                                              [_vm._v("Sessão")]
                                            ),
                                            _c(
                                              "vs-th",
                                              { attrs: { width: "10%" } },
                                              [_vm._v("Início")]
                                            ),
                                            _c(
                                              "vs-th",
                                              { attrs: { width: "10%" } },
                                              [_vm._v("Qtd. Questões")]
                                            ),
                                            _c(
                                              "vs-th",
                                              { attrs: { width: "10%" } },
                                              [_vm._v("Qtd. Respondidas")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          })
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", { attrs: { width: "10%" } }, [
                          _vm._v("Nome"),
                        ]),
                        _c("vs-th", { attrs: { width: "15%" } }, [
                          _vm._v("Email"),
                        ]),
                        _c("vs-th", { attrs: { width: "5%" } }, [
                          _vm._v("Matrícula"),
                        ]),
                        _c("vs-th", { attrs: { width: "70%" } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "grid grid-cols-3 justify-items-center flex w-full",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex justify-center" },
                                [
                                  _c(
                                    "vs-chip",
                                    {
                                      staticClass: "text-base",
                                      attrs: {
                                        transparent: "",
                                        color: "success",
                                      },
                                    },
                                    [_vm._v("Não iniciada")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "flex justify-center" },
                                [
                                  _c(
                                    "vs-chip",
                                    {
                                      staticClass: "text-base",
                                      attrs: {
                                        transparent: "",
                                        color: "warning",
                                      },
                                    },
                                    [_vm._v("Iniciada")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "flex justify-center" },
                                [
                                  _c(
                                    "vs-chip",
                                    {
                                      staticClass: "text-base",
                                      attrs: {
                                        transparent: "",
                                        color: "primary",
                                      },
                                    },
                                    [_vm._v("Finalizada")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }